.App {
  text-align: center;
}

html {
  height:100%;
}

.main-contents > div {
  height:100%;
  display:flex;
  flex-flow:column;
}

body{
  height:100%;
  background: #dfdfdf;
}

main{
  height:100%;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
}

#root {
  height:100%;
}

.strike {
  background-color: inherit;
  background-image: repeating-linear-gradient(45deg, silver 0%, silver 45%, black 50%, silver 55%, silver 100%);
}

.visibility-button {
  margin-left:5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-container {
  min-height:100%;
  margin:0;
  display:grid;
  grid-template-rows: auto 80px;
}

.footer {
  height: 80px;
  width:100%;
  text-align:center;
}

.footer h4{
  font-size: 12px;
  padding-bottom:1px;
  margin:0;
}

.footer hr{
  margin-bottom: 5px;
}

 /*Common Css accross pages*/

  .k-grid-header-wrap{
    margin-right:0;
  }

 .textURLOut { 
  color:#007dbb !important;
  }

  .sortableHeader {
    color:#007dbb!important;
  }

 .no-header .k-height-container, .no-header .k-grid-header {
  background: transparent;
  color: transparent;
  height: 0;
  display:none;
}

.no-header thead {
  background: transparent;
  color: transparent;
  height: 0;
  display:none;
}

img {
height:100%;
}

.noMargin {
  margin:0;
}

input:disabled {
  background-color: #dfdfdf;
}

.custom-checkmark:checked {
  background-color: #007dbb;
}

.redX{
  color:red;
  font-size: 20px;
  text-align: center;
}

.greenCheck{
  color:green;
  font-size: 20px;
  text-align: center;
}

/*for rangepicker*/
.k-daterange-picker, .k-daterangepicker {
width:auto;
}

.k-picker-md .k-input-inner {
padding: 4px 8px;
text-align:center;
}

.k-floating-label-container > .k-label, .k-floating-label-container.k-focus > .k-label {
top: 0;
left: 0;
transform: scale(1);
font-size: 16px;
text-align: center;
left: auto;
margin-left: auto;
width: 100%;
margin: 0px 8px;
}

.noContent tbody{
  width:0;
  color:transparent;
  background-color: transparent;
  display:none;
}

.topper{
display:flex;
}

.left-bold .k-grid td:first-child {
  font-weight:bold;
  color:black;
}

.left-normal .k-grid td:first-child{
font-weight:normal;
}

.align-center .k-grid td:first-child, .align-right .k-grid td:first-child {
text-align: left;
}

.align-left .k-grid td, .align-left .k-column-title {
text-align: left;
}

.align-left .k-cell-inner{ 
  justify-content:left;
}

.leftAlign {
  text-align: left !important;
}

.align-center .k-grid-header .k-header:first-child > .k-cell-inner,
.align-right .k-grid-header .k-header:first-child > .k-cell-inner {
justify-content:left;
}

.align-center .k-grid td, .align-center .k-column-title {
text-align: center;
}

.align-center .k-cell-inner{ 
justify-content:center;
}

.align-right .k-grid td, .align-right .k-column-title {
text-align: right;
}

.align-right .k-cell-inner{ 
justify-content:right;
}

.normal .k-cell-inner{ 
  background-color: white;
  color:black;
}

.red .k-cell-inner{ 
  background-color: red;
  color:white;
  font-weight:bold;
  font-size:20px;
}

.red .k-table-th.k-header {
  background-color:red;
}

.red .k-grid-header .k-header {
background-color:red;
}

.normal .k-table-th.k-header {
  background-color:white;
}

.normal .k-grid-header .k-header {
background-color:white;
}

.sortableHeader .k-cell-inner .k-link .k-column-title{
  color:#0088cc!important;
}

.headerTable, .no-header {
width:100%;
}

.headerTable .k-height-container, .headerTable .k-grid-header {
  background: transparent;
}

.headerTable .k-column-title{
  white-space: normal;
  word-break: break-word;
  overflow: visible;
  font-weight:bold;
}

th.k-header, .k-header, .k-grid td {
  border:none;
  color:black;
  }

.headerTable .k-grid-content table, .scrollTable .k-grid-content table{
  border:none;
}

.scrollTable .k-height-container, .scrollTable .k-grid-header {
  background: transparent;
  color: transparent;
}

.headerTable .k-column-title, .scrollTable .k-column-title{
  white-space: normal;
  word-break: break-word;
  overflow: visible;
  font-weight:bold;
}

.k-grid tr.k-alt {
background-color: white;
}

.k-grid tr{
background-color: #f5f5f5;
}

.k-grid-content{
  background-color: transparent;
}

.k-grid{
  background-color: inherit;
  border: none;
  font-size:16px;
}

.k-cell-inner{ 
  justify-content:center;
}

.k-grid td {
  text-align: left;
}

.k-grid {
font-size:16px;
}

.tab, .tabRight { 
margin-right: 20px ;
}

.tabLeft { 
margin-left: 20px ;
}

.title-grid {
font-size:18px;
color:black;
margin-top: 0;
margin-bottom: 0;
}

.subtitle-grid {
font-size:14px;
margin-top: 0;
margin-bottom: 0;
}

.set-right {
margin-left:auto;
}

.blue-button, .blue-button-hover-only.k-selected {
border-color: #0088cc;
background-color: #0088cc;
}

.blue-button:hover, .blue-button-hover-only:hover {
border-color: #007dbb;
background-color: #007dbb;
}

.black-button, .black-button-hover-only.k-selected {
border-color: black;
background-color: black;
color:white;
border-radius: 12px;
padding: 6px 25px;
margin-left:auto;
margin-right:10px;
}

.black-button:hover, .black-button-hover-only:hover {
border-color: #111111;
background-color: #111111;
color:white;
border-radius: 12px;
padding: 6px 25px;
margin-left:auto;
margin-right:10px;
}

.unclickable {
color:black;
background-color:#dfdfdf;

}

.clickable {
color:white;
background-color:#0088cc;
border-color: #0088cc;
}

.clickable:hover {
border-color: #007dbb;
background-color: #007dbb;

}

.k-command-cell{
  text-align: right !important;
}

/*for dropdown hover color*/
.k-list .k-list-item:hover, 
.k-list .k-list-optionlabel:hover, 
.k-list .k-list-item.k-hover, 
.k-list .k-hover.k-list-optionlabel {
color: #fff;
background-color: #0088cc;
}

/*for dropdown selected color*/
.k-list .k-list-item.k-selected, 
.k-list .k-selected.k-list-optionlabel{
color: black;
background-color: silver;
}

.commandButton{
  margin-right:10px;
  margin-left:auto;
  border:none;
  padding: 6px 25px;
  font-size:26px;
  color:#0088cc;
  font-weight:bold;
  background-color:transparent;
}

.textButton{
color:#0088cc;
}

.textButton:hover{
cursor:pointer;
}

.full{
height: 100%;
width:100%;
}

.bold{
font-weight:bold;
}

.pWhiteBack{
text-align:left;
width:60%;
height:100%;
background-color:white;
color:black;
margin:auto;
border:silver 1px solid;
display:flex;
}

h4 {
text-align:center;
width:100%;
}

.h5Normal {
text-align:center;
width:100%;
background-color:white;
padding-top:10px;
padding-bottom:10px;
color:black;
}

.white-text{
color:white;
}

.defaultbutton{
margin-right:10px;
margin-top:10px;
margin-bottom:10px;
margin-left:auto;
border-radius: 12px;
border: 1px silver solid;
padding: 6px 25px;
}

.newDocButton{
border-radius: 12px;
border: 1px silver solid;
padding: 0px 25px;
color:white;
font-weight:bold;
font-size:large;
height: 100%;
}

.H4red{
font-size: 25px;
font-weight:bold;
padding: 10px;
color: red;
text-align:left;
}

.H4Banner {
text-align:left;
width:100%;
background-color:red;
color:white;
margin:0;
}

.H4RedHeaderDupe {
text-align:left;
width:100%;
background-color:red;
color:white;
margin:0;
padding: 10px 12px;
font-weight:bold;
font-size:20px;
}

.H4WhiteBack {
text-align:left;
width:100%;
background-color:white;
color:black;
margin:0;
}

.H4BlueBack {
text-align:left;
width:100%;
color:#0088cc;
margin:0;
}

.k-pager-numbers .k-link {
color: red;
}

.k-pager-numbers .k-link.k-selected {
color: red;
background-color: rgba(255, 0, 0, 0.2);
}

.k-pager-numbers .k-link:hover, .k-pager-numbers .k-link.k-hover {
color: red;
background-color: rgba(255, 0, 0, 0.08);
}

.k-grid-pager {
order: -1;
border-width: 0 0 1px;
}

.k-pager-info {
text-align: left;
justify-content: flex-start;
flex: 1 1;
order: -1;
}

.defaultDropdown{
width: 100%;
min-width: 15ch;
max-width: 30ch;
border: 1px solid gray;
padding: 0.25em 0.5em;
font-size: 1.25rem;
cursor: pointer;
line-height: 1.1;
background-color: #fff;
background-image: linear-gradient(to top, silver, #fff 75%);
font-weight:bold;
}

.defaultDropdown:disabled{
  background-color: #dfdfdf;
  border-radius: 0;
  background-image: none;
}

/*for toggleFlip component*/
.button-cell-no-button, .button-cell-yes-button {
  color:white;
  margin-top:10px;
  margin-bottom:10px;
  border: 1px silver solid;
  padding: 6px 25px;
  font-weight:bold;
}

.button-cell-no-button{
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.button-cell-yes-button{
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.button-cell-yes-button.clicked {
  background-color: green;
}

.button-cell-yes-button.unclicked {
  background-color: gray;
}

.button-cell-no-button.clicked {
  background-color: red;
}

.button-cell-no-button.unclicked {
  background-color: gray;
}

.button-cell-no-button:disabled, .button-cell-yes-button:disabled{
  color:black;
}



.numberStyle{
font-size:40px;
color: black;
font-weight: bold;
display: flex;
align-items: flex-end;
justify-content: center;
height:100%;
width:100%;
}

.footStyle{
color: black;
display: flex;
align-items: flex-end;
justify-content: center;
height:100%;
width:100%;
}

.footRedStyle{
color: red;
display: flex;
align-items: flex-end;
justify-content: center;
height:100%;
width:100%;
font-weight:bold;
}

textarea {
resize: both;
max-width: 100%;
}

textarea:disabled{
  resize:none;
}

.square-button{
font-size:30px;
padding-left:15px;
padding-right:15px;
margin-bottom: auto;
}

.topbar {
display: flex;
margin: 10px 10px;
border: #a5a5a5 1px solid;
background-color:#dfdfdf;
}

.topbarText {
font-weight: bold;
width: 100%;
text-align: center;
margin-top: 0;
margin-bottom: 0;
}

.inputWidthSync{
  width:91%;
  border-radius: 4px;
  border-color: #dfdfdf;
}

.popupHeaderSmall{
  font-size: 16px;
  text-align: left;
  width: 100%;
  margin-bottom:0px;
}

.grayHeaderBar{
  background-color: #dfdfdf;
  width:100%;
}
.gray-header{
  background-color: #dfdfdf;
  width:100%;
  font-size:20px;
  font-weight:bold;
  padding-top: 10px;
  padding-bottom:10px;
  color:black;
}

  /*Dialog*/
.k-window-title, .k-dialog-titlebar{
  font-weight: bold;
  color: black;
  font-size: 20px;
  background-color:#0088cc;
}

.k-window-content, .k-prompt-container{
  padding:0px 15px;
}

.dialog-title-text{
  margin: 20px 0px;
  text-align: left;
  font-weight:bold;
  font-size:18px;
}

.dialog-subtitle-text{
  margin: 20px 0px;
  text-align: left;
  font-size:16px;
}

.k-dialog-buttongroup{
  margin-left:auto;
  border: none;
  padding:0;
}

.k-dialog-wrapper .k-dialog{
  width:80% !important;
  overflow:scroll;
}

.leftSide { grid-area: leftSide;}
.leftSide2 { grid-area: leftSide2;}
.buttonLayout { grid-area: buttonLayout;}
.buttonLayout2 { grid-area: buttonLayout2;}

.leftSide > p, .leftSide2 > p {
  margin: 16px;
}

.buttonLayout, .buttonLayout2 {
  justify-content: right;
  align-content: center;
  display: flex;
  margin-right:20px;
}

/* for most view.tsx files */

.subtitle {
  font-size: 18px;
  margin: 0;
}
.MenuItem2, .MenuItem3 {
  color: white;
  background-color: black;
  font-size: 18px;
}

.popup-profile-input, .popup-preferences-input, .popup-password-input {
  width:91%;
}

/*for buttonCellDropdown */
.button-cell-more-button.clicked{
  background-color:lightgray;
  min-height: 40px;
  min-width: 40px;
  font-size:30px;
}

.button-cell-dropdown-button {
  position: relative;
  display: inline-block;
}

.button-cell-more-options {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right:0px;
  text-align:start;
}

.button-cell-more-options a {
  color: black;
  padding: 12px 20px;
  text-decoration: none;
  display: block;
}

.button-cell-more-options a:hover {background-color: #ddd; cursor:pointer;}

/*for semiCircleGauges */
.sc-gauge  { 
  width:200px ;
  height:200px ;
  margin-top:50px;
  margin-left: auto;
  margin-right: auto;
}
.sc-background { 
  position:relative;
  height:100px ;
  margin-bottom:10px ;
  background-color:#fff ;
  border-radius:150px 150px 0 0 ;
  overflow:hidden ;
  text-align:center ;
}
.sc-mask { 
  position:absolute ;
  top:50px ;
  right:50px ;
  left:50px ;
  height:80px ;
  background-color:#dfdfdf ;
  border-radius:150px 150px 0 0;
}

.charging-mask { 
  position:absolute ;
  left:137px ;
  height:318px ;
  width:0px;
  background-color:rgba(0,0,0,.15) ;
}

.sc-percentage { 
  position:absolute ;
  top:100px ;
  left:-200% ;
  width:400% ;
  height:400% ;
  margin-left:100px ;
}
.pink{
  background-color:pink;
}
.red{
  background-color:red;
}
.gray{
  background-color:silver;
}
.maroon{
  background-color:maroon;
}
.black{
  background-color:black;
}
.blackColor{
  color:black;
}
.pinkColor{
  color:pink;
}
.redColor{
  color:red;
}
.grayColor{
  color:silver;
}
.maroonColor{
  color:maroon;
}
.sc-percentage { 
  transform:rotate(25deg) ;
  transform-origin:top center ;
}
.sc-min { 
  float:left ;
}
.sc-max { 
  float:right ;
}
.sc-key {
  font-weight:bold;
  top: -50px;
  position: relative;
}

/* css for maps */
.map-container {
  position: relative;
  overflow: hidden;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.mapboxgl-map {
  overflow: hidden;
  background-color: white;
  height:100%;
  width:100%;
}

.mapboxgl-canvas {
  height:100%;
  width:100%;
}

.marker {
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  background-image: url("../public/fire.png");
}
.mapboxgl-popup {
  max-width: 200px;
}
.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  white-space: break-spaces;
  padding:10px;
  line-height:14px;
  text-align:left;
}

.mapboxgl-popup-content > p {
  margin:0;
  font-size:10px;
}

.mapboxgl-popup-content > h3 {
  font-size:12px;
}

/*For Slider*/
.k-slider-horizontal{
  width:100%;
}

.k-slider .k-slider-track, .k-slider .k-slider-selection {
  border-radius: 20px;
  background-color: silver;
}

.k-slider-horizontal .k-slider-track, .k-slider-horizontal .k-slider-selection{
  height:25px;
  margin-top: 0px;
  top: 0%;
}
.k-slider .k-draghandle{
  border-color: red;
  background-color:red;
  color:white;
  font-size: 18px;
  font-weight:bold;
  border-radius: 25px;
  user-select: none;
}

.k-draghandle {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  outline: 0;
  position: absolute;
  text-align: center;
  text-decoration: none;
  width: 70px;
  height: 30px;
}

/*manage profile popup*/
.popup-profile-firstName-dropdown{grid-area: popup-profile-firstName-dropdown;}
.popup-profile-lastName-dropdown{grid-area: popup-profile-lastName-dropdown;}
.popup-profile-email-dropdown{grid-area: popup-profile-email-dropdown;}
.popup-profile-office-dropdown{grid-area: popup-profile-office-dropdown;}
.popup-profile-mobile-dropdown{grid-area: popup-profile-mobile-dropdown;}

.popup-profile-contents {
    display: grid;
    width:100%;
    grid-template-columns: repeat(2, minmax(0,50%));
    grid-template-areas:
    'popup-profile-firstName-dropdown popup-profile-office-dropdown'
    'popup-profile-lastName-dropdown popup-profile-mobile-dropdown'
    'popup-profile-email-dropdown . ';
    padding:15px;
    gap:15px;
  }

/*change preferences popup*/
.popup-preferences-faults-dropdown{grid-area: popup-preferences-faults-dropdown;}
.popup-preferences-timeZone-dropdown{grid-area: popup-preferences-timeZone-dropdown;}
.popup-preferences-deliveryTime-dropdown{grid-area: popup-preferences-deliveryTime-dropdown;}
.popup-preferences-csv-dropdown{grid-area: popup-preferences-csv-dropdown;}
.popup-preferences-deliveryDay-dropdown{grid-area: popup-preferences-deliveryDay-dropdown;}
.popup-preferences-mapApp-dropdown{grid-area: popup-preferences-mapApp-dropdown;}
.popup-preferences-mapType-dropdown{grid-area: popup-preferences-mapType-dropdown;}
.popup-preferences-records-dropdown{grid-area: popup-preferences-records-dropdown;}
.popup-preferences-decimals-dropdown{grid-area: popup-preferences-decimals-dropdown;}
.popup-preferences-units-dropdown{grid-area: popup-preferences-units-dropdown;}
.popup-preferences-firstDay-dropdown{grid-area: popup-preferences-firstDay-dropdown;}
.popup-preferences-dateNumberFormat-dropdown{grid-area: popup-preferences-dateNumberFormat-dropdown;}

.popup-preferences-contents {
    display: grid;
    width:100%;
    grid-template-columns: repeat(3, minmax(0,33%));
    grid-template-areas:
    'popup-preferences-dateNumberFormat-dropdown popup-preferences-decimals-dropdown popup-preferences-faults-dropdown'
    'popup-preferences-timeZone-dropdown popup-preferences-records-dropdown popup-preferences-deliveryDay-dropdown'
    'popup-preferences-firstDay-dropdown popup-preferences-mapType-dropdown popup-preferences-deliveryTime-dropdown'
    'popup-preferences-units-dropdown popup-preferences-mapApp-dropdown popup-preferences-csv-dropdown';
    padding:15px;
    gap:15px;
  }

/*change password popup*/
.popup-password-currentPassword-dropdown{grid-area: popup-password-currentPassword-dropdown;}
.popup-password-newPassword-dropdown{grid-area: popup-password-newPassword-dropdown;}
.popup-password-confirmNewPassword-dropdown{grid-area: popup-password-confirmNewPassword-dropdown;}
.popup-password-resetAnswer-dropdown{grid-area: popup-password-resetAnswer-dropdown;}
.popup-password-question-dropdown{grid-area: popup-password-question-dropdown;}

.popup-password-contents {
    display: grid;
    width:100%;
    grid-template-columns: repeat(2, minmax(0,50%));
    grid-template-areas:
    'popup-password-currentPassword-dropdown popup-password-question-dropdown'
    'popup-password-newPassword-dropdown popup-password-resetAnswer-dropdown'
    'popup-password-confirmNewPassword-dropdown . ';
    padding:15px;
    gap:15px;
  }
  